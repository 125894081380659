/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~simple-line-icons/css/simple-line-icons.css';

@import '~spinkit/css/spinkit.css';
@import '~loaders.css/loaders.css';

@import '~ngx-toastr/toastr.css';

@import '~ng2-dnd/bundles/style.css';

@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-fresh.css';

@import '~ika.jvectormap/jquery-jvectormap-1.2.2.css';

@import '~jqcloud2/dist/jqcloud.css';

@import '~summernote/dist/summernote.css';


@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';
@import '~@fullcalendar/bootstrap/main.css';

@import '~codemirror/lib/codemirror.css';
//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";
modal-container+modal-container {
  background: rgba(0, 0, 0, 0.5);
}
//datepicker added universally
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
.bs-datepicker-head > bs-datepicker-navigation-view {
    display: flex;
    justify-content: space-around;
}

/* Custom Theme */
.theme-angle {
    .bs-datepicker-head {
        background-color: $info;
    }
    .bs-datepicker-body table td {
        span.selected, &.selected span,
        span[class*="select-"]:after,
        &[class*="select-"] span:after {
            background-color: $info;
        }
        &.week span {
          color: $info;
        }
    }
}
#gdpr-cookie-message {
  background: white; padding: 10px; margin: 10px; width:300px; border: solid black 1px; bottom: 100px; right: 10px; position: fixed; z-index:1000
}
#gdpr-cookie-advanced{
  display:none;
}
#gdpr {
  position: fixed;
  bottom: 0;
  width:50px;
  z-index:100;
  /*padding: 50px;
  font-size: 20px;*/
}
.hover-space {
  cursor: pointer; 
}
.leaf-green{
  color: #1edb28
}

.leaf-brown{
  color: #F77600
}
.modal-open {
    overflow: scroll;
}
input.ng-invalid.ng-touched {
  border: 1px solid red;
}

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
.ql-container {
     height: 300px !important;
 }

.highlights {
  background-color: #FFFF00 !important;
}